import React, {MouseEvent, ReactElement, useCallback} from 'react'
import Plus from '../../../../../asset/image/plus_icon_gray.svg'
import {CBusDispatchTable} from '../../../../../model/Bus'
import styled from 'styled-components'
import {flexColumn} from '../../../../../style/CommonStyle'
import SVGImage from '../../../../common/SVGImage'
import {WeekdayEnum} from '../../../../../enum/WeekdayEnum'
import PeriodTableItemExist from './PeriodTableItemExist'
import {isEmptyString, isNil} from '../../../../../util/ValidationUtil'

type Props = {
  dispatchTable: CBusDispatchTable
  selectedDispatchTables: CBusDispatchTable[]
  isStart: boolean
  weekday: WeekdayEnum
  classTime: string
  lengthPerCell: number
  isDeleting: boolean
  onClickInfo(
    ct: string,
    wd: WeekdayEnum,
    isStart: boolean,
    dt: CBusDispatchTable,
  ): void
  onClickAdd(
    ct: string,
    wd: WeekdayEnum,
    isStart: boolean,
    dt: CBusDispatchTable,
  ): void
  onClickCell(dt: CBusDispatchTable): void
}

export default function PeriodTableItem(props: Props): ReactElement {
  const onClickInfo = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      props.onClickInfo(
        props.classTime,
        props.weekday,
        props.isStart,
        props.dispatchTable,
      )
    },
    [props.onClickInfo],
  )

  const onClickAdd = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      props.onClickAdd(
        props.classTime,
        props.weekday,
        props.isStart,
        props.dispatchTable,
      )
    },
    [props.onClickAdd, props.classTime, props.weekday, props.isStart],
  )

  if (!props.dispatchTable.status.isExistsDispatch) {
    return <Empty />
  }

  if (
    !isEmptyString(props.dispatchTable.dispatch.courseName) &&
    !isEmptyString(props.dispatchTable.dispatch.courseTime)
  ) {
    return (
      <PeriodTableItemExist
        lengthPerCell={props.lengthPerCell}
        dispatchTable={props.dispatchTable}
        selectedDispatchTables={props.selectedDispatchTables}
        isStart={props.isStart}
        isDeleting={props.isDeleting}
        onClickInfo={onClickInfo}
        onClickCell={props.onClickCell}
      />
    )
  }

  return (
    <AddButton onClick={onClickAdd}>
      <AddIcon source={Plus} />
    </AddButton>
  )
}

const AddButton = styled.div`
  border: 0.1rem solid #ebebeb;
  border-radius: 0.4rem;
  flex: 1;
  min-height: 3.6rem;
  ${flexColumn};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const AddIcon = styled(SVGImage)`
  width: 1.8rem;
  height: 1.8rem;
`

const Empty = styled.div`
  border: 0.1rem solid #ebebeb;
  border-radius: 0.4rem;
  flex: 1;
  min-height: 3.6rem;
  ${flexColumn};
  justify-content: center;
  align-items: center;
  cursor: default;
`
