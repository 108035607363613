import React, {SetStateAction, useCallback} from 'react'
import styled from 'styled-components'
import {flexRow} from '../../../../../style/CommonStyle'
import {DispatchOptionEnum} from '../../../../../enum/DispatchOptionEnum'
import {CDispatchPeriodInfo} from '../../../../../model/DispatchPeriod'
import Modal, {ModalRef} from '../../../../common/Modal'
import DispatchSetting from '../setting/DispatchSetting'
import PeriodHeader from '../PeriodHeader'
import useSecureRef from '../../../../../hook/useSecureRef'
import {CBusBrief, CBusDispatchTable} from '../../../../../model/Bus'
import Toast, {ToastRef} from '../../../../modal/Toast'
import {Optional} from '../../../../../type/Common'
import {isNil} from '../../../../../util/ValidationUtil'
import Picker, {PickerOption} from '../../../../input/Picker'
import {CDate} from '../../../../../model/Date'
import Dispatch from '../../../../../page/dispatch/Dispatch'

type Props = {
  busBrief: CBusBrief
  dispatchOptions: PickerOption<DispatchOptionEnum>[]
  dispatchPeriodOptions: PickerOption<CDispatchPeriodInfo>[]
  selectedDispatchPeriod: Optional<CDispatchPeriodInfo>
  selectedDispatchTables: CBusDispatchTable[]
  defaultDispatchIndex: number
  defaultDispatchPeriodIndex: number
  isSelectedAll: boolean
  isDeleting: boolean
  onChangeDispatch(d: DispatchOptionEnum): void
  onChangeDispatchPeriod(dp: CDispatchPeriodInfo): void
  onClickSelectAll(): void
  onClickDelete(): void
  onClickCancelDelete(): void
  onSubmitDelete(): void
  onSubmitSetting(): void
  setToast(tc: string): void
  deleteDate: CDate
  setDeleteDate: (date: CDate) => void
}

export default function PeriodTableHeader(props: Props) {
  const settingModalRef = useSecureRef<ModalRef>(
    '[PeriodTableHeader.tsx] settingModalRef',
  )
  const toastRef = useSecureRef<ToastRef>(
    '[DispatchPeriodSetting.tsx] toastRef',
  )

  const showSettingModal = useCallback(() => {
    settingModalRef.current().show()
  }, [])

  const onSubmitSetting = useCallback(() => {
    props.setToast('운행 설정이 완료되었습니다.')
    props.onSubmitSetting()
  }, [props.setToast, props.onSubmitSetting])

  const onCancelSetting = useCallback(() => {
    settingModalRef.current().hide()
  }, [])

  return (
    <HeaderContainer>
      <Toast ref={toastRef.ref} />
      <Modal ref={settingModalRef.ref} zIndex={2}>
        <DispatchSetting
          busName={props.busBrief.bus.name}
          busID={props.busBrief.bus.id}
          dispatchPeriod={props.selectedDispatchPeriod}
          onSubmit={onSubmitSetting}
          onCancel={onCancelSetting}
        />
      </Modal>

      <TitleWrapper>
        <Title>배차 정보</Title>
        <SelectContainer>
          <Picker
            options={props.dispatchOptions}
            defaultIdx={props.defaultDispatchIndex}
            onChange={props.onChangeDispatch}
            minWidth={84}
            height={28}
          />
          <Picker
            minWidth={120}
            height={28}
            options={props.dispatchPeriodOptions}
            onChange={props.onChangeDispatchPeriod}
            defaultIdx={props.defaultDispatchPeriodIndex}
          />
        </SelectContainer>
      </TitleWrapper>
      {isNil(props.selectedDispatchPeriod) ? null : (
        <PeriodHeader
          showSettingModal={showSettingModal}
          dispatchPeriod={props.selectedDispatchPeriod}
          isSelectedAll={props.isSelectedAll}
          selectedDispatchTables={props.selectedDispatchTables}
          onClickSelectAll={props.onClickSelectAll}
          isDeleting={props.isDeleting}
          onClickDelete={props.onClickDelete}
          onClickCancelDelete={props.onClickCancelDelete}
          onSubmitDelete={props.onSubmitDelete}
          deleteDate={props.deleteDate}
          setDeleteDate={props.setDeleteDate}
        />
      )}
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  ${flexRow};
  align-items: center;
  justify-content: space-between;
  height: 6.4rem;
  margin-top: -1.2rem;
  position: sticky;
  top: -1.2rem;
  background: #fff;
  padding: 1.2rem 0;
  z-index: 1;
`

const TitleWrapper = styled.div`
  ${flexRow};
  align-items: center;
`

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin-left: 0.8rem;
  margin-right: 1.2rem;
`

const SelectContainer = styled.div`
  ${flexRow};
  column-gap: 0.4rem;
  align-items: center;
`
